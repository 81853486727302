<template>
  <el-dialog
    title="选择项目"
    :visible="visible"
    :custom-class="customClass"
    append-to-body
    :close-on-click-modal="false"
    @close="$emit('update:visible', false)"
  >
    <p class="content">
      <el-radio-group
        v-model="selectedVal"
        class="download"
        @change="handleChange"
      >
        <el-radio
          v-for="item in list"
          :key="item.ccid"
          :label="item.ccid"
        >
          {{ item.name }}
        </el-radio>
      </el-radio-group>
    </p>
    <span
      slot="footer"
      class="dialog-panel-footer"
    >
      <template>
        <el-button
          size="mini"
          @click="handleCloseDialog"
        >取消</el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="handleConfirmVisible"
          :debounce="3000"
          @debounce="handleDebounce"
          @click="handleConfirm"
        >确定</el-button>
      </template>

    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      customClass: 'select-project-dialog',
      selectedVal: '',
      handleConfirmVisible: true,
    };
  },
  computed: {},
  methods: {
    // BP发送请求接口
    async handleChange() {
      this.handleConfirmVisible = false;
    },
    handleConfirm() {
      const selected = this.list.find((item) => item.ccid === this.selectedVal);
      this.$emit('confirm', selected);
      this.handleCloseDialog();
    },
    handleCloseDialog() {
      this.selectedVal = '';
      this.handleConfirmVisible = true;
      this.$emit('update:visible', false);
    },
    handleDebounce() {
      this.$message({
        message: '操作太频繁了，请稍后执行~',
        type: 'warning',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog .el-dialog__body {
  max-height: 622px;
  overflow: auto;
}

/deep/ .select-project-dialog {
  .content {
    padding: 20px 0;

    .el-radio-group {
      text-align: left;

      .el-radio {
        display: block;
        line-height: 20px;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .is-disabled {
        .el-radio__label {
          color: #cacee0;
        }
      }

      .el-radio__label {
        font-size: 14px !important;
        color: #333b44;
        padding-left: 8px;
      }

      .el-radio__inner {
        width: 14px !important;
        height: 14px !important;
      }
    }
  }
}
</style>
